import React from 'react';
import AudioRecording from '../components/firebase/audioRecording';
import '../css/componentStyles.css';
import '../css/App.css';
import '../css/index.css';
import PhonemicStructureUpload from '../components/firebase/phonemicStructureUpload';

const Tests = () => {
    return (
      <div>

        <h1>
          Phonemic Structure Upload

          <PhonemicStructureUpload />

        </h1>


      </div>
      
      );
  };
  
export default Tests;