import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase";
import { collection, doc, getDocs, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import './modifyCurriculum.css';

library.add(faFloppyDisk, faPenToSquare);

const ItemTypes = {
  PHONEMIC_STRUCTURE: 'phonemicStructure',
};

const structureTypeMapping = {
  consonantblends: "Consonant Blends",
  shortvowels: "Short Vowels",
  consonants: "Consonants",
  longvowels: "Long Vowels",
  prefixes: "Prefixes",
  suffixes: "Suffixes",
};

export default function ModifyCurriculum({ teacherDocumentID }) {
  const [objectives, setObjectives] = useState(0);
  const [structureTypes, setStructureTypes] = useState({});
  const [curriculum, setCurriculum] = useState([]);
  const [existingCurriculum, setExistingCurriculum] = useState(null);
  const [gradeLevel, setGradeLevel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchPhonemicStructures = async () => {
      const structureTypes = ["consonantblends", "shortvowels", "consonants", "longvowels", "prefixes", "suffixes"];
      let fetchedStructures = {};

      for (const type of structureTypes) {
        const structuresCollectionRef = collection(firestore, 'phonemicStructures', type, 'structures');
        const structuresSnapshot = await getDocs(structuresCollectionRef);
        fetchedStructures[type] = structuresSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      }

      setStructureTypes(fetchedStructures);
    };

    fetchPhonemicStructures().then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const fetchCurriculum = async () => {
      const teacherDocRef = doc(firestore, 'users', teacherDocumentID);
      const teacherDocSnap = await getDoc(teacherDocRef);

      if (teacherDocSnap.exists()) {
        const teacherData = teacherDocSnap.data();
        if (teacherData.curriculumName) {
          const curriculumDocRef = doc(firestore, 'curriculums', teacherData.curriculumName);
          const curriculumDocSnap = await getDoc(curriculumDocRef);

          if (curriculumDocSnap.exists()) {
            const curriculumData = curriculumDocSnap.data();
            setObjectives(curriculumData.numberOfObjectives);
            const curriculumObjectives = [];

            for (let i = 1; i <= curriculumData.numberOfObjectives; i++) {
              const objectiveDocRef = doc(curriculumDocRef, 'objectives', `objective${i}`);
              const objectiveSnap = await getDoc(objectiveDocRef);
              if (objectiveSnap.exists()) {
                const { phonemicStructures } = objectiveSnap.data();
                const items = phonemicStructures.map(({ phonemicTranslation, englishTranslation }) => ({
                  phonemicTranslation,
                  englishTranslation,
                }));
                curriculumObjectives.push(items);
              }
            }
            setCurriculum(curriculumObjectives);
            setExistingCurriculum(curriculumData);
          }
        }

        if (teacherData.gradeLevel) {
          setGradeLevel(teacherData.gradeLevel);
        }
      }
    };

    fetchCurriculum().then(() => setIsLoading(false));
  }, [teacherDocumentID]);

  const DraggableStructure = ({ id, phonemicTranslation, englishTranslation }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: ItemTypes.PHONEMIC_STRUCTURE,
      item: { id, phonemicTranslation, englishTranslation },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }), [id, phonemicTranslation, englishTranslation]);

    return (
      <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
        {phonemicTranslation}, {englishTranslation}
      </div>
    );
  };

  const handleRemoveStructure = (objectiveNumber, index) => {
    setCurriculum(curriculum => {
      const newCurriculum = [...curriculum];
      newCurriculum[objectiveNumber - 1].splice(index, 1);
      return newCurriculum;
    });
  };

  const DroppableObjective = ({ objectiveNumber, items }) => {
    const [{ isOver }, drop] = useDrop(() => ({
      accept: ItemTypes.PHONEMIC_STRUCTURE,
      drop: (item) => handleDrop(objectiveNumber, item),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }), [objectiveNumber]);

    return (
      <div ref={drop} className={`objectiveBox ${isEditing ? 'editable' : ''}`}>
        <div className='objectiveHeader'>
          <p>Objective {objectiveNumber}</p>
        </div>
        <div className='droppedPhonemes'>
          {items.map((item, index) => (
            <PhonemeItem
              key={index}
              phonemicTranslation={item.phonemicTranslation}
              englishTranslation={item.englishTranslation}
              onClick={() => isEditing && handleRemoveStructure(objectiveNumber, index)}
              isEditing={isEditing}
            />
          ))}
        </div>
      </div>
    );
  };

  const PhonemeItem = ({ phonemicTranslation, englishTranslation, onClick, isEditing }) => {
    const [hovered, setHovered] = useState(false);

    return (
      <div
        className='phonemeItem'
        onMouseEnter={() => isEditing && setHovered(true)}
        onMouseLeave={() => isEditing && setHovered(false)}
        onClick={onClick}
      >
        {hovered && isEditing ? 'X' : `${phonemicTranslation}, ${englishTranslation}`}
      </div>
    );
  };

  const handleDrop = (objectiveNumber, item) => {
    setCurriculum(curriculum => {
      const newCurriculum = [...curriculum];
      newCurriculum[objectiveNumber - 1] = [...newCurriculum[objectiveNumber - 1], item];
      return newCurriculum;
    });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);

    try {
      const curriculumData = {
        numberOfObjectives: objectives,
      };

      if (gradeLevel) {
        curriculumData.gradeLevel = gradeLevel;
      }

      if (!existingCurriculum) {
        const curriculumDocRef = doc(firestore, 'curriculums', teacherDocumentID);
        await setDoc(curriculumDocRef, curriculumData);

        const teacherDocRef = doc(firestore, 'users', teacherDocumentID);
        await updateDoc(teacherDocRef, {
          curriculumName: teacherDocumentID
        });
      }

      for (let i = 0; i < objectives; i++) {
        const objectiveDocRef = doc(firestore, 'curriculums', teacherDocumentID, 'objectives', `objective${i + 1}`);
        const objectiveItems = curriculum[i];
        const phonemicStructures = objectiveItems.map(item => ({
          phonemicTranslation: item.phonemicTranslation,
          englishTranslation: item.englishTranslation,
        }));

        await setDoc(objectiveDocRef, {
          objectiveNumber: i + 1,
          phonemicStructures,
        });
      }

      alert('Curriculum updated successfully!');
    } catch (error) {
      console.error('Error updating curriculum:', error);
      alert('Error updating curriculum. Please try again.');
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='headerInfo'>
        <span>
          <label htmlFor="objectives">Number of Objectives (Ex: 16): {objectives} </label>
        </span>
        {!isEditing ? (
          <button onClick={handleEditClick}><FontAwesomeIcon className='icons' icon="fa-solid fa-pen-to-square" /></button>
        ) : (
          <button onClick={handleSaveClick}><FontAwesomeIcon className='icons' icon="fa-solid fa-floppy-disk" /></button>
        )}
      </div>

      <div className='curriculumCreatorContainer'>
        <div className='boxesContainer'>
          <div>
            {Array.from({ length: objectives }, (_, i) => (
              <DroppableObjective
                key={i + 1}
                objectiveNumber={i + 1}
                items={curriculum[i]}
              />
            ))}
          </div>
        </div>

        <div className='draggablesContainer' style={{ filter: existingCurriculum && isEditing ? 'grayscale(0)' : 'grayscale(1)' }}>
          {Object.entries(structureTypes).map(([type, structures]) => (
            <div className={type} key={type}>
              <p>{structureTypeMapping[type]}</p>
              <section className='blockContainer'>
                {structures.map(structure => (
                  <DraggableStructure
                    key={structure.id}
                    id={structure.id}
                    phonemicTranslation={structure.phonemicTranslation}
                    englishTranslation={structure.englishTranslation}
                  />
                ))}
              </section>
            </div>
          ))}
        </div>
      </div>
    </DndProvider>
  );
}
