import React, { useState, useEffect } from 'react';
import '../css/App.css';
import '../css/index.css';
import BannerNoImg from '../components/banners/bannerNoImg';
import OurGoal from '../components/informative/ourGoal';
import SubBanner from '../components/banners/subBanner';
import Features from '../components/informative/features';
import WhatsNext from '../components/informative/whatsNext';
import StartToday from '../components/informative/startToday';
import NewDemo from '../components/demo/newDemo';
import Steps from '../components/informative/steps';
import StoryGenPhonemic from '../components/demo/newDemo';


//Home page function, rendered in layout

const Home = () => {

    return (
        <div className='App'>
            <link href='https://fonts.googleapis.com/css?family=Raleway' rel='stylesheet' />
            <BannerNoImg />
             <main className="page">
                <SubBanner />
                <NewDemo />
                <Features />
                <OurGoal />
                <Steps />
                <WhatsNext />
                <StartToday />
            </main>
        </div>
    )};
  
  export default Home;