import React, { useEffect, useState } from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase.js';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faXTwitter, faSquareFacebook } from '@fortawesome/free-brands-svg-icons';

import logo from '../images/babblebot.png';
import '../css/layout.css';
//import '../css/componentStyles.css';
//import '../css/App.css';


library.add( faBars, faXmark, faArrowRight, faInstagram, faXTwitter, faSquareFacebook )

const Layout = () => {

  const [user, setUser] = useState("");
  let linkText = 'Login';
  let linkValue = '/signin';
  

  onAuthStateChanged(auth, (currentUser) => { // keeps user current when refreshing / changing state of site
      setUser(currentUser);
  })

  if (!user){
    linkText = 'Login' //send user back to signup if not authenticated
    linkValue = '/signin'
  } else {
    linkText = 'Dashboard'
    linkValue = '/teacherdash' 
  }

  function openCloseHamburger() {
    const links = document.getElementById("menu-items-mobile");
    const lines = document.getElementById('lines');
    const x = document.getElementById("x");

    if (lines.style.display === 'block') {
      lines.style.transition = '0.2s ease-in-out';
      links.style.transition = '0.5s ease-in-out';
      setTimeout(() => {
          lines.style.display = 'none';
          x.style.display = 'block';
          links.style.display = 'flex';
          links.style.width = '100dvw';
          links.style.height = '95dvh';
      }, 200);
    } else {
        x.style.transition = '0.2s ease-in-out';
        setTimeout(() => {
            x.style.display = 'none';
            lines.style.display = 'block';
            links.style.width = '0px';
        }, 200);
    }
  }

  return (
    <>
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap" rel="stylesheet"></link>
      <nav id='nav'>
        <div className="navbar">
          {/* Desktop Navbar */}
          {window.location.pathname === '/studentDash' || window.location.pathname === '/lessonSession' ? (
            <div className="menu-items-desktop">
            <Link to="/"><img className='menulogo' alt='Babblebot Robot' src={logo} /></Link>
            <ul>
              <li><Link className="link-desktop navBtn" to='/'>Logout<FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIconNav' /></Link></li>
            </ul>
          </div>
          ) : (
            <div className="menu-items-desktop">
                <Link to="/"><img className='menulogo' alt='Babblebot Robot' src={logo} /></Link>
                <ul>
                  <li><Link className="link-desktop" to="/">Home</Link></li>
                  <li><a className="link-desktop active" href="https://babblebot.notion.site/BabbleBot-Documentation-0e0816f3653142d0ac237a30dc6a92d4" rel='noreferrer' target="_blank">About</a></li>
                  <li><Link className='link-desktop' to='/blog'>Blog</Link></li>
                  {/*<li><Link className='link-desktop' to='/signup'>Signup</Link></li>*/}
                  <li><Link className="link-desktop navBtn" to={linkValue}>{linkText}<FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIconNav' /></Link></li>
                </ul>
              </div>
          )}
          <div className="navMobile">
              {/* Mobile Navbar */}
              <div className="logo">
                <Link to="/"><img className='logo' alt='Babblebot Robot' src={logo} /></Link>
              </div>
              <div id="hamburgerLines">
              <FontAwesomeIcon icon="fa-solid fa-bars" className='hamburgerLines' id="lines" onClick={(e) => {openCloseHamburger()}} style={{transition: '0.2s ease-in-out', display: 'block'}} />
                  <FontAwesomeIcon icon="fa-solid fa-xmark" className='hamburgerX' id="x" onClick={(e) => {openCloseHamburger()}} style={{ display: 'none'}} />
              </div>  
          </div>
          <div className="menu-items-mobile" id='menu-items-mobile' style={{ width: '0px', overflow: 'hidden'}}>
            <div className='siteLinks'>
                <Link className="link" to="/" onClick={(e) => {openCloseHamburger()}}>Home</Link>
                <a className="link" href="https://babblebot.notion.site/BabbleBot-Documentation-0e0816f3653142d0ac237a30dc6a92d4" rel='noreferrer' target="_blank">About</a>
                {/*<Link className="link" to="/signup">Signup</Link>*/}
                <Link className='link' to="/blog" onClick={(e) => {openCloseHamburger()}}>Blog</Link>
                <Link className="link" to="/signin" onClick={(e) => {openCloseHamburger()}}>Login</Link>
            </div>

            <div className='navSocials'>
                <a>Twitter</a>
                <a>LinkedIn</a>
                <a>Facebook</a>
            </div>
          </div>
        </div>
      </nav>

      <Outlet />

      <footer id='footer'>
        <div className='footerContainer'>

          <div className='footerAbout'>
            <p className='footerHeader'>About</p>
            <p className='footerText'>Babblebot is a FREE online educational resource, aiming to give students 
              and educators a fun and central way to learn phonemes. </p>
          </div>

          <div className='pageLinks'>
            <p className='footerHeader'>Navigation</p>
            <ul className='footerList'>
              <a className='footerLink' href='/#nav'>
                <li>Home <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIcon' /></li>
              </a>
              <a className='footerLink' href='/blog#nav'>
                <li>Blog <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIcon' /></li>
              </a>
              <a className="footerLink" href="https://babblebot.notion.site/BabbleBot-Documentation-0e0816f3653142d0ac237a30dc6a92d4" rel='noreferrer' target="_blank">
                <li>About <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIcon' /></li>
              </a>
              {/*<Link className="footerLink" to="/signup"><li>Signup <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIcon' /></li></Link>*/}
              <a className="footerLink" href="/signin#nav">
                <li>Login <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIcon' /></li>
              </a>
            </ul>
          </div>

          <div className="socials">
            <p className='footerHeader'>Follow Us!</p>
            <a className='footerLink' href="https://www.instagram.com"><FontAwesomeIcon icon="fa-brands fa-instagram" className="footerBrands" /></a>
            <a className='footerLink' href="https://www.x.com"><FontAwesomeIcon icon="fa-brands fa-x-twitter" className='footerBrands'/></a>
            <a className='footerLink' href="https://www.facebook.com"><FontAwesomeIcon icon="fa-brands fa-square-facebook" className='footerBrands'/></a>
          </div>

          <div className="Copyright">
            <p>&copy; Copyright 2024 | Rising Tide Learning LLC | All Rights Reserved | <a href="https://www.vecteezy.com/free-vector/character">Character Vectors by Vecteezy</a></p>
            <div>
              <a className='privacyPolicy'>Privacy Policy</a>
              <a> | </a>
              <a className='tos'>Terms of Service</a>
            </div>
          </div>
        </div>
      </footer> 
      </>   
  )
};

export default Layout;
