import React from "react";
import '../../css/blog.css';

export default function FullBlog( {blogData, selectedBlog, setSelectedBlog }) {
    return (
        <div className="fullBlog">
            <div className="heroSection" style={{backgroundImage: `url(${blogData[selectedBlog].imgUrl})`}}>
                <button onClick={(e) => setSelectedBlog(null)}>X</button>
            </div>
            <div className="titleWrapper">
                    <h2>{blogData[selectedBlog].title}</h2>
                    <div className="tagsContainer">
                        {blogData[selectedBlog].tags.map((tag, index) => (
                            <p key={index}>{tag}</p>
                        ))}
                    </div>
            </div>
            <div className="bodyContent">
                {blogData[selectedBlog].body.map((body, index) => (
                    <p key={index}>{body}</p>
                ))}
            </div>
        </div>
    )
}