import React from 'react';
import './ourGoal.css';


export default function OurGoal() {
    return (
        <div className='goalContainer'>
            <h2>Our Goal</h2>
            <p>BabbleBot is here to make learning early English skills safe, accessible, and 
                fun for every student! Our platform combines the latest in artificial intelligence
                 with proven Science of Reading practices to create an engaging learning experience.
                  BabbleBot is designed to support both students and teachers, making early literacy
                   skill-building easier and more enjoyable. Join us and see how BabbleBot can make a
                    difference in your classroom!
            </p>
        </div>
    )
}